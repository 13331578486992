var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var DX,EX,FX,HX,IX,JX,KX;DX=new $CLJS.N("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);EX=new $CLJS.N("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
FX=new $CLJS.N("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.GX=new $CLJS.N("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);HX=new $CLJS.N("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);
IX=new $CLJS.N("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);JX=new $CLJS.N("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);KX=new $CLJS.N("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.xV($CLJS.hJ,$CLJS.G([$CLJS.wu,$CLJS.kJ,$CLJS.Rt,$CLJS.nL]));$CLJS.BL.o(null,$CLJS.GX,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.CL(a);return $CLJS.le(a)?(a=$CLJS.Ez.h(a,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.NI,null,$CLJS.RJ,null],null),null)),$CLJS.F.h($CLJS.E(a),1)?$CLJS.C(a):a):a});$CLJS.mV($CLJS.GX,$CLJS.OL);
for(var LX=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cK,$CLJS.KK],null)),MX=null,NX=0,OX=0;;)if(OX<NX){var PX=MX.$(null,OX);$CLJS.xV(PX,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.IL],null),$CLJS.Rt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.nL],null)]));$CLJS.mV(PX,$CLJS.GX);OX+=1}else{var QX=$CLJS.A(LX);if(QX){var RX=QX;if($CLJS.re(RX)){var SX=$CLJS.$c(RX),Ima=$CLJS.ad(RX),Jma=SX,Kma=$CLJS.E(SX);LX=Ima;MX=Jma;NX=Kma}else{var TX=$CLJS.C(RX);$CLJS.xV(TX,$CLJS.G([new $CLJS.S(null,2,5,
$CLJS.T,[$CLJS.hq,$CLJS.IL],null),$CLJS.Rt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.nL],null)]));$CLJS.mV(TX,$CLJS.GX);LX=$CLJS.D(RX);MX=null;NX=0}OX=0}else break}
for(var UX=$CLJS.A(new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.DI,$CLJS.XH,$CLJS.$J,$CLJS.vK,$CLJS.fK,$CLJS.CI,$CLJS.RK],null)),VX=null,WX=0,XX=0;;)if(XX<WX){var Lma=VX.$(null,XX);$CLJS.xV(Lma,$CLJS.G([$CLJS.wu,$CLJS.el,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.IL],null)],null)]));XX+=1}else{var YX=$CLJS.A(UX);if(YX){var ZX=YX;if($CLJS.re(ZX)){var $X=$CLJS.$c(ZX),Mma=$CLJS.ad(ZX),Nma=$X,Oma=$CLJS.E($X);UX=Mma;VX=Nma;WX=Oma}else{var Pma=$CLJS.C(ZX);$CLJS.xV(Pma,$CLJS.G([$CLJS.wu,
$CLJS.el,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.IL],null)],null)]));UX=$CLJS.D(ZX);VX=null;WX=0}XX=0}else break}$CLJS.xV($CLJS.gJ,$CLJS.G([$CLJS.wu,$CLJS.el,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.IL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.IL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.EV],null)]));
for(var aY=$CLJS.A(new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.RK,null,$CLJS.fK,null,$CLJS.vK,null,$CLJS.iK,null,$CLJS.CI,null,$CLJS.XH,null,$CLJS.DI,null,$CLJS.$J,null],null),null)),bY=null,cY=0,dY=0;;)if(dY<cY){var Qma=bY.$(null,dY);$CLJS.xV(Qma,$CLJS.G([$CLJS.wu,$CLJS.el,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.IL],null)],null)]));dY+=1}else{var eY=$CLJS.A(aY);if(eY){var fY=eY;if($CLJS.re(fY)){var gY=$CLJS.$c(fY),Rma=$CLJS.ad(fY),Sma=gY,Tma=$CLJS.E(gY);
aY=Rma;bY=Sma;cY=Tma}else{var Uma=$CLJS.C(fY);$CLJS.xV(Uma,$CLJS.G([$CLJS.wu,$CLJS.el,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.IL],null)],null)]));aY=$CLJS.D(fY);bY=null;cY=0}dY=0}else break}$CLJS.iL(KX,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Gt,$CLJS.uI,$CLJS.pJ,$CLJS.HJ],null));
$CLJS.zV($CLJS.bK,$CLJS.G([$CLJS.wu,$CLJS.el,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.AJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.IL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ck,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,KX],null)],null)],null)],null)]));
$CLJS.iL(JX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,$CLJS.tL,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,2,[$CLJS.cv,"valid timezone ID",$CLJS.ev,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.ml);return["invalid timezone ID: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.Pe($CLJS.Le,$CLJS.UL.tz.names()))],null));
$CLJS.zV($CLJS.tI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.AJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.IL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ZK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,JX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rF,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,JX],null)],null)],null)],null)]));
$CLJS.mV($CLJS.tI,$CLJS.GX);$CLJS.xV($CLJS.CJ,$CLJS.G([$CLJS.wu,$CLJS.VI]));$CLJS.iL(FX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.yL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.cv,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Xf(function(b){return $CLJS.NH(a,b)},new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.NI,$CLJS.RJ],null))}],null)],null));
$CLJS.iL(DX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.xL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ql,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CG,new $CLJS.k(null,1,[$CLJS.Sj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,FX],null)],null)],null)],null));
$CLJS.tV($CLJS.EI,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.xt,new $CLJS.k(null,1,[$CLJS.cv,"valid :absolute-datetime clause"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zl,$CLJS.EI],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,DX],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zt,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.xt,new $CLJS.k(null,1,[$CLJS.cv,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,4,
5,$CLJS.T,[$CLJS.yk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.tX],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.vX],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.oX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zl,$CLJS.Qj],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.FV],null)],null)],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.xt,new $CLJS.k(null,1,[$CLJS.cv,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zl,$CLJS.mB],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.xX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zl,$CLJS.Qj],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.DV],null)],null)],null)],null)],null)],null));
$CLJS.BL.o(null,$CLJS.EI,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);a=$CLJS.F.h(b,$CLJS.mB)?$CLJS.F.h(a,$CLJS.Qj)?$CLJS.RJ:$CLJS.m($CLJS.oV($CLJS.FV,a))?$CLJS.NI:$CLJS.RJ:null;if($CLJS.m(a))return a;a="string"===typeof b?$CLJS.m($CLJS.Di($CLJS.BX,b))?$CLJS.NI:$CLJS.m($CLJS.Di($CLJS.CX,b))?$CLJS.NI:null:null;if($CLJS.m(a))return a;b=$CLJS.CL(b);b=$CLJS.le(b)?$CLJS.oh.j($CLJS.oi,$CLJS.tg(function(c){return $CLJS.NH(c,$CLJS.El)}),b):b;return $CLJS.le(b)&&
$CLJS.F.h($CLJS.E(b),1)?$CLJS.C(b):b});$CLJS.iL(IX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zl,$CLJS.mB],null),$CLJS.Rt],null));
$CLJS.zV($CLJS.qJ,$CLJS.G([$CLJS.wu,$CLJS.RJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tC,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,IX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.nL],null)],null)],null)],null)]));
$CLJS.xV($CLJS.YD,$CLJS.G([$CLJS.wu,$CLJS.WK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.QL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.mL],null)]));$CLJS.iL(EX,new $CLJS.S(null,12,5,$CLJS.T,[$CLJS.Gt,$CLJS.eJ,$CLJS.fn,$CLJS.ln,$CLJS.WI,$CLJS.TK,$CLJS.YK,$CLJS.kn,$CLJS.gn,$CLJS.en,$CLJS.hn,$CLJS.HI],null));$CLJS.iL(HX,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Gt,$CLJS.uI,$CLJS.pJ,$CLJS.HJ],null));
$CLJS.zV($CLJS.nK,$CLJS.G([$CLJS.wu,$CLJS.el,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.AJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.IL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,EX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ck,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,HX],null)],null)],
null)],null)]));