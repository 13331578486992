var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./schema.core.js");
'use strict';var HN,IN;HN=function(a){if(null!=a&&null!=a.Sd)a=a.Sd(a);else{var b=HN[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=HN._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("HasPrecondition.precondition",a);}return a};IN=function(a,b,c,d){this.Vc=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};$CLJS.JN=function(a){return new IN(a,null,null,null)};$CLJS.rM.prototype.Sd=$CLJS.Ma(59,function(){return $CLJS.$f(this.na)});
$CLJS.wM.prototype.Sd=$CLJS.Ma(58,function(){var a=this;return $CLJS.Em.h($CLJS.$f(a.na),$CLJS.R.h($CLJS.Fm,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.Qf(q);q=$CLJS.M.h(u,$CLJS.tM);u=$CLJS.M.h(u,$CLJS.Vl);q=$CLJS.m(q)?$CLJS.Em.h(q,HN($CLJS.NM(u))):HN($CLJS.NM(u));l.add(q);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):
$CLJS.Ef($CLJS.Gf(l),null)}l=$CLJS.C(e);f=$CLJS.Qf(l);l=$CLJS.M.h(f,$CLJS.tM);f=$CLJS.M.h(f,$CLJS.Vl);return $CLJS.nf($CLJS.m(l)?$CLJS.Em.h(l,HN($CLJS.NM(f))):HN($CLJS.NM(f)),d($CLJS.zd(e)))}return null}},null,null)}(a.options)}()))});$CLJS.EM.prototype.Sd=$CLJS.Ma(57,function(){return $CLJS.$f(this.na)});$CLJS.h=IN.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "schemas":return this.Vc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.CondPre{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kN,this.Vc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.kN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-56613580^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.Vc,b.Vc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.kN,null],null),null),b)?$CLJS.vm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new IN(this.Vc,this.F,$CLJS.Rf($CLJS.vm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "schemas":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.kN,b):$CLJS.O.call(null,$CLJS.kN,b))?new IN(c,this.F,this.m,null):new IN(this.Vc,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.kN,this.Vc)],null),this.m))};$CLJS.h.O=function(a,b){return new IN(this.Vc,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};
$CLJS.h.Db=function(){var a=this;return $CLJS.xM($CLJS.MM,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n);q=new $CLJS.k(null,2,[$CLJS.tM,HN($CLJS.NM(q)),$CLJS.Vl,q],null);l.add(q);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}l=$CLJS.C(e);return $CLJS.nf(new $CLJS.k(null,2,[$CLJS.tM,HN($CLJS.NM(l)),
$CLJS.Vl,l],null),d($CLJS.zd(e)))}return null}},null,null)}(a.Vc)}(),function(b){return new $CLJS.gf(null,$CLJS.lha,new $CLJS.gf(null,b,null,1,null),2,null)})};$CLJS.h.Fa=function(){return $CLJS.nf($CLJS.dha,$CLJS.rg.h($CLJS.OM,this.Vc))};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};$CLJS.KN=$CLJS.XM($CLJS.CN,$CLJS.$f($CLJS.OF),"Non-blank string");$CLJS.LN=$CLJS.TM(function(a){return $CLJS.NH(a,$CLJS.jl)},"Valid field type");$CLJS.MN=$CLJS.TM(function(a){return $CLJS.NH(a,$CLJS.Zk)||$CLJS.NH(a,$CLJS.Rj)},"Valid semantic type");$CLJS.NN=$CLJS.XM($CLJS.FN,$CLJS.cf,"positive integer");$CLJS.ON=$CLJS.XM($CLJS.FN,$CLJS.$f($CLJS.ef),"integer \x3e\x3d 0");$CLJS.wha=$CLJS.Pf([$CLJS.BN,$CLJS.BN]);$CLJS.xha=$CLJS.JN($CLJS.G([$CLJS.GN,$CLJS.CN]));