var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var n2,o2,q2,r2;$CLJS.l2=function(a){if("number"===typeof a)return a;switch(a instanceof $CLJS.N?a.S:null){case "current":return 0;case "next":return 1;case "last":return-1;default:return 0}};
$CLJS.m2=function(a,b){a=$CLJS.l2(a);b=$CLJS.m(b)?b:$CLJS.wz;return 0===a?$CLJS.F.h(b,$CLJS.wz)?$CLJS.UH("Today"):$CLJS.TH("This {0}",$CLJS.G([$CLJS.E1.g(b)])):$CLJS.F.h(a,1)?$CLJS.F.h(b,$CLJS.wz)?$CLJS.UH("Tomorrow"):$CLJS.TH("Next {0}",$CLJS.G([$CLJS.E1.g(b)])):$CLJS.F.h(a,-1)?$CLJS.F.h(b,$CLJS.wz)?$CLJS.UH("Yesterday"):$CLJS.TH("Previous {0}",$CLJS.G([$CLJS.E1.g(b)])):0>a?$CLJS.TH("Previous {0} {1}",$CLJS.G([Math.abs(a),$CLJS.E1.h(Math.abs(a),b)])):0<a?$CLJS.TH("Next {0} {1}",$CLJS.G([a,$CLJS.E1.h(a,
b)])):null};n2=new $CLJS.N("metabase.lib.filter","unary","metabase.lib.filter/unary",-336199667);o2=new $CLJS.N("metabase.lib.filter","varargs","metabase.lib.filter/varargs",1417033079);$CLJS.p2=new $CLJS.N(null,"operators","operators",-2064102509);q2=new $CLJS.N("metabase.lib.filter","compound","metabase.lib.filter/compound",1924103780);r2=new $CLJS.N("metabase.lib.filter","binary","metabase.lib.filter/binary",-1921360491);for(var s2=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Lj,$CLJS.yk],null)),t2=null,u2=0,v2=0;;)if(v2<u2){var mpa=t2.$(null,v2);$CLJS.mV(mpa,q2);v2+=1}else{var w2=$CLJS.A(s2);if(w2){var x2=w2;if($CLJS.re(x2)){var y2=$CLJS.$c(x2),npa=$CLJS.ad(x2),opa=y2,ppa=$CLJS.E(y2);s2=npa;t2=opa;u2=ppa}else{var qpa=$CLJS.C(x2);$CLJS.mV(qpa,q2);s2=$CLJS.D(x2);t2=null;u2=0}v2=0}else break}
for(var z2=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zl,$CLJS.BK],null)),A2=null,B2=0,C2=0;;)if(C2<B2){var rpa=A2.$(null,C2);$CLJS.mV(rpa,o2);C2+=1}else{var D2=$CLJS.A(z2);if(D2){var E2=D2;if($CLJS.re(E2)){var F2=$CLJS.$c(E2),spa=$CLJS.ad(E2),tpa=F2,upa=$CLJS.E(F2);z2=spa;A2=tpa;B2=upa}else{var vpa=$CLJS.C(E2);$CLJS.mV(vpa,o2);z2=$CLJS.D(E2);A2=null;B2=0}C2=0}else break}
for(var G2=$CLJS.A(new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.$l,$CLJS.lm,$CLJS.bm,$CLJS.km,$CLJS.tJ,$CLJS.lK,$CLJS.JJ,$CLJS.uJ],null)),H2=null,I2=0,J2=0;;)if(J2<I2){var wpa=H2.$(null,J2);$CLJS.mV(wpa,r2);J2+=1}else{var K2=$CLJS.A(G2);if(K2){var L2=K2;if($CLJS.re(L2)){var M2=$CLJS.$c(L2),xpa=$CLJS.ad(L2),ypa=M2,zpa=$CLJS.E(M2);G2=xpa;H2=ypa;I2=zpa}else{var Apa=$CLJS.C(L2);$CLJS.mV(Apa,r2);G2=$CLJS.D(L2);H2=null;I2=0}J2=0}else break}
for(var N2=$CLJS.A(new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.wI,$CLJS.mJ,$CLJS.JI,$CLJS.ZI,$CLJS.Pk],null)),O2=null,P2=0,Q2=0;;)if(Q2<P2){var Bpa=O2.$(null,Q2);$CLJS.mV(Bpa,n2);Q2+=1}else{var R2=$CLJS.A(N2);if(R2){var S2=R2;if($CLJS.re(S2)){var T2=$CLJS.$c(S2),Cpa=$CLJS.ad(S2),Dpa=T2,Epa=$CLJS.E(T2);N2=Cpa;O2=Dpa;P2=Epa}else{var Fpa=$CLJS.C(S2);$CLJS.mV(Fpa,n2);N2=$CLJS.D(S2);O2=null;P2=0}Q2=0}else break}
$CLJS.y1.o(null,$CLJS.MZ,function(a,b){var c=$CLJS.Rf($CLJS.MZ.g($CLJS.U0(a,b)));return $CLJS.m(c)?$CLJS.TH("Filtered by {0}",$CLJS.G([$CLJS.b2($CLJS.UH("and"),function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u);v=$CLJS.$0.D(a,b,v,$CLJS.a1);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);
return $CLJS.nf($CLJS.$0.D(a,b,q,$CLJS.a1),f($CLJS.zd(g)))}return null}},null,null)}(c)}())])):null});
$CLJS.v1.o(null,q2,function(a,b,c,d){c=$CLJS.A(c);var e=$CLJS.C(c);c=$CLJS.D(c);$CLJS.C(c);var f=$CLJS.D(c);return $CLJS.b2(function(){var g=e instanceof $CLJS.N?e.S:null;switch(g){case "and":return $CLJS.UH("and");case "or":return $CLJS.UH("or");default:throw Error(["No matching clause: ",$CLJS.p.g(g)].join(""));}}(),function(){return function n(l){return new $CLJS.yf(null,function(){for(;;){var q=$CLJS.A(l);if(q){if($CLJS.re(q)){var u=$CLJS.$c(q),v=$CLJS.E(u),x=$CLJS.Bf(v);a:for(var y=0;;)if(y<
v){var B=$CLJS.be(u,y);B=$CLJS.$0.D(a,b,B,d);x.add(B);y+=1}else{u=!0;break a}return u?$CLJS.Ef($CLJS.Gf(x),n($CLJS.ad(q))):$CLJS.Ef($CLJS.Gf(x),null)}x=$CLJS.C(q);return $CLJS.nf($CLJS.$0.D(a,b,x,d),n($CLJS.zd(q)))}return null}},null,null)}(f)}())});
$CLJS.v1.o(null,o2,function(a,b,c,d){var e=$CLJS.A(c);c=$CLJS.C(e);e=$CLJS.D(e);$CLJS.C(e);var f=$CLJS.D(e);e=$CLJS.rg.h(function(g){return $CLJS.$0.D(a,b,g,d)},f);if($CLJS.F.h($CLJS.E(f),2))switch(f=$CLJS.J(e,0,null),e=$CLJS.J(e,1,null),c=c instanceof $CLJS.N?c.S:null,c){case "\x3d":return $CLJS.TH("{0} equals {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.TH("{0} does not equal {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}else switch(f=$CLJS.C(e),e=$CLJS.b2($CLJS.UH("or"),
$CLJS.zd(e)),c=c instanceof $CLJS.N?c.S:null,c){case "\x3d":return $CLJS.TH("{0} equals any of {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.TH("{0} does not equal any of {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}});
$CLJS.v1.o(null,r2,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);var f=$CLJS.J(c,2,null);c=$CLJS.J(c,3,null);f=$CLJS.$0.D(a,b,f,d);a=$CLJS.$0.D(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "\x3c":return $CLJS.TH("{0} is less than {1}",$CLJS.G([f,a]));case "\x3c\x3d":return $CLJS.TH("{0} is less than or equal to {1}",$CLJS.G([f,a]));case "\x3e":return $CLJS.TH("{0} is greater than {1}",$CLJS.G([f,a]));case "\x3e\x3d":return $CLJS.TH("{0} is greater than or equal to {1}",$CLJS.G([f,
a]));case "starts-with":return $CLJS.TH("{0} starts with {1}",$CLJS.G([f,a]));case "ends-with":return $CLJS.TH("{0} ends with {1}",$CLJS.G([f,a]));case "contains":return $CLJS.TH("{0} contains {1}",$CLJS.G([f,a]));case "does-not-contain":return $CLJS.TH("{0} does not contain {1}",$CLJS.G([f,a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});
$CLJS.v1.o(null,$CLJS.II,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null),f=$CLJS.J(c,3,null);c=$CLJS.J(c,4,null);return $CLJS.TH("{0} is between {1} and {2}",$CLJS.G([$CLJS.$0.D(a,b,e,d),$CLJS.$0.D(a,b,f,d),$CLJS.$0.D(a,b,c,d)]))});
$CLJS.v1.o(null,$CLJS.TI,function(a,b,c,d){$CLJS.J(c,0,null);var e=$CLJS.J(c,1,null),f=$CLJS.J(c,2,null),g=$CLJS.J(c,3,null),l=$CLJS.J(c,4,null),n=$CLJS.J(c,5,null),q=$CLJS.J(c,6,null);c=$CLJS.J(c,7,null);return $CLJS.$0.D(a,b,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lj,e,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.II,e,f,q,l],null),new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.II,e,g,n,c],null)],null),d)});
$CLJS.v1.o(null,n2,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);a=$CLJS.$0.D(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "is-null":return $CLJS.TH("{0} is empty",$CLJS.G([a]));case "not-null":return $CLJS.TH("{0} is not empty",$CLJS.G([a]));case "is-empty":return $CLJS.TH("{0} is empty",$CLJS.G([a]));case "not-empty":return $CLJS.TH("{0} is not empty",$CLJS.G([a]));case "not":return $CLJS.TH("not {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",
$CLJS.p.g(e)].join(""));}});$CLJS.v1.o(null,$CLJS.pK,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null),f=$CLJS.J(c,3,null);c=$CLJS.J(c,4,null);return $CLJS.TH("{0} is within {1}",$CLJS.G([$CLJS.$0.D(a,b,e,d),$CLJS.m2(f,c).toLowerCase()]))});$CLJS.v1.o(null,$CLJS.hY,function(a,b,c){a=$CLJS.Qf(c);return $CLJS.M.h(a,$CLJS.SI)});
$CLJS.B1.o(null,$CLJS.hY,function(a,b,c){b=$CLJS.Qf(c);a=$CLJS.M.h(b,$CLJS.BV);b=$CLJS.M.h(b,$CLJS.SI);return new $CLJS.k(null,2,[$CLJS.t1,$CLJS.iG(a),$CLJS.SI,b],null)});