var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var gla,hla,ila,jla,kla,lla,mla;$CLJS.DV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);gla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);hla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);
$CLJS.EV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.FV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);ila=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);
jla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);kla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);lla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);
$CLJS.GV=new $CLJS.N("type","temporal-bucketing-option","type/temporal-bucketing-option",1870188464);$CLJS.HV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.IV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
mla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var JV=new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.gn,$CLJS.kn,$CLJS.jn,$CLJS.dn,$CLJS.ln,$CLJS.fn,$CLJS.Jj],null),KV=$CLJS.si(JV),nla,pla,TV;$CLJS.iL(kla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,1,[$CLJS.cv,"Valid date extraction unit"],null)],null),KV));var LV=new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.wz,$CLJS.Uk,$CLJS.fl,$CLJS.Uj,$CLJS.Jj],null),MV=$CLJS.si(LV);
$CLJS.iL(jla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,1,[$CLJS.cv,"Valid date truncation unit"],null)],null),MV));$CLJS.NV=$CLJS.oh.j($CLJS.Lg,$CLJS.$m.A(),$CLJS.qg.h(LV,JV));nla=$CLJS.si($CLJS.NV);$CLJS.iL($CLJS.FV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,1,[$CLJS.cv,"Valid date bucketing unit"],null)],null),nla));var OV=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hn,$CLJS.en],null),PV=$CLJS.si(OV);
$CLJS.iL(ila,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,1,[$CLJS.cv,"Valid time extraction unit"],null)],null),PV));var QV=new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.sz,$CLJS.rz,$CLJS.tz,$CLJS.vz],null),RV=$CLJS.si(QV);$CLJS.iL(mla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,1,[$CLJS.cv,"Valid time truncation unit"],null)],null),RV));
$CLJS.ola=$CLJS.oh.j($CLJS.Lg,$CLJS.Dm.h($CLJS.Gm.g(new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.rz,null,$CLJS.sz,null],null),null)),$CLJS.$m.A()),$CLJS.qg.h(QV,OV));pla=$CLJS.si(OV);$CLJS.iL(hla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,1,[$CLJS.cv,"Valid time bucketing unit"],null)],null),pla));$CLJS.SV=$CLJS.oh.j($CLJS.Lg,$CLJS.Dm.h($CLJS.Gm.g(new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.rz,null,$CLJS.sz,null],null),null)),$CLJS.$m.A()),$CLJS.qg.l(QV,LV,$CLJS.G([OV,JV])));
TV=$CLJS.si($CLJS.SV);$CLJS.iL($CLJS.DV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,1,[$CLJS.cv,"Valid datetime bucketing unit"],null)],null),TV));var qla=$CLJS.kf.h(TV,$CLJS.Qj);$CLJS.iL($CLJS.HV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,1,[$CLJS.cv,"Valid temporal bucketing unit"],null)],null),qla));var rla=$CLJS.xz.h(MV,RV);
$CLJS.iL($CLJS.EV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,1,[$CLJS.cv,"Valid datetime truncation unit"],null)],null),rla));$CLJS.UV=$CLJS.xz.h(KV,PV);$CLJS.iL(gla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,1,[$CLJS.cv,"Valid datetime extraction unit"],null)],null),$CLJS.UV));var VV=$CLJS.kf.h(MV,$CLJS.Jj);$CLJS.iL($CLJS.IV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,1,[$CLJS.cv,"Valid date interval unit"],null)],null),VV));
$CLJS.iL($CLJS.mL,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,1,[$CLJS.cv,"Valid time interval unit"],null)],null),RV));var sla=$CLJS.xz.h(VV,RV);$CLJS.iL($CLJS.nL,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,1,[$CLJS.cv,"Valid datetime interval unit"],null)],null),sla));
$CLJS.iL(lla,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ql,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zl,$CLJS.GV],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,$CLJS.HV],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qj,new $CLJS.k(null,1,[$CLJS.Sj,!0],null),$CLJS.ik],null)],null));